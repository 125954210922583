.text-wrap {
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-xl);
    max-width: 800px;
}
.outer-wrapper {
    display: inline-grid;
    align-content: center;
    justify-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
